<template>
  <section class="section is-primary pb-1">
    <div class="container has-background-white-ter">
      <div
        class="box"
        style="
          display: flex;
          justify-content: space-between;
          padding-bottom: 1em;
        "
      >
        <strong class="is-small-caps">{{ details?.form?.title }}</strong>
        <router-link
          class="button"
          :to="{ path: '/forms/id/' + $route.params.formID + '/edit' }"
          >Manage</router-link
        >
      </div>

      <table class="table is-bordered is-fullwidth">
        <thead>
          <tr>
            <th>Reference</th>
            <th>Date of Submission</th>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="x in details?.submissions ?? []" :key="x.key">
            <td>
              <router-link
                :to="{
                  path:
                    '/forms/id/' +
                    $route.params.formID +
                    '/submission/' +
                    x.date +
                    '/' +
                    x.key,
                }"
                >{{ x.reference }}</router-link
              >
            </td>
            <td>{{ formatDate(x.date) }}</td>
            <td>{{ x.firstname }} {{ x.lastname }}</td>
            <td>{{ x.email }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
//import { Icon } from "@iconify/vue2";
//import lunch from "helpers/lunch";
//import { format, sub as datesub } from 'date-fns' ;
//import { sum } from 'pdf-lib';
//import { toast } from 'bulma-toast'

import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

import { RouterLink } from "vue-router";

//import LunchOption from "./LunchOption.vue";

export default {
  name: "home",

  components: {
    RouterLink,
  },

  data: function () {
    return {
      details: null,
      loaded: false,
      wibble: null,
    };
  },

  methods: {
    formatDate(x) {
      let y = parseISO(x);

      return format(y, "do LLL yyyy HH:mm");
    },
  },

  async mounted() {
    this.loaded = false;
    this.$api
      .get(
        "v2/staff/forms/" + this.$route.params.formID + "/summary",
        true,
        true
      )
      .then((response) => {
        this.details = response;
      })
      .finally(() => {
        this.loaded = true;
      });

    //this.loadRegister();
  },
};
</script>
