var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section is-primary pb-1"},[_c('div',{staticClass:"container has-background-white-ter"},[_c('div',{staticClass:"box",staticStyle:{"display":"flex","justify-content":"space-between","padding-bottom":"1em"}},[_c('strong',{staticClass:"is-small-caps"},[_vm._v(_vm._s(_vm.details?.form?.title))]),_c('router-link',{staticClass:"button",attrs:{"to":{ path: '/forms/id/' + _vm.$route.params.formID + '/edit' }}},[_vm._v("Manage")])],1),_c('table',{staticClass:"table is-bordered is-fullwidth"},[_vm._m(0),_c('tbody',_vm._l((_vm.details?.submissions ?? []),function(x){return _c('tr',{key:x.key},[_c('td',[_c('router-link',{attrs:{"to":{
                path:
                  '/forms/id/' +
                  _vm.$route.params.formID +
                  '/submission/' +
                  x.date +
                  '/' +
                  x.key,
              }}},[_vm._v(_vm._s(x.reference))])],1),_c('td',[_vm._v(_vm._s(_vm.formatDate(x.date)))]),_c('td',[_vm._v(_vm._s(x.firstname)+" "+_vm._s(x.lastname))]),_c('td',[_vm._v(_vm._s(x.email))])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Reference")]),_c('th',[_vm._v("Date of Submission")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Email")])])])
}]

export { render, staticRenderFns }